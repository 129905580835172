import { SelectOption } from "../components/Select/Select";

import { VariableType } from "./types";

export const variableOptions: SelectOption[] = [
  {
    id: VariableType.NUMBER,
    value: "Number",
  },
  {
    id: VariableType.NUMBER_ARRAY,
    value: "Number Array",
  },
  {
    id: VariableType.STRING,
    value: "String",
  },
  {
    id: VariableType.STRING_ARRAY,
    value: "String Array",
  },
];
