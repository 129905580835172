import {
  Checkbox,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { Experiment } from "../../../types/types";
import { getEmail } from "../../../utils/Utils";

import OwnerControlButtons from "./OwnerControlButtons";
import TableFilter from "./TableFilter";

interface ExperimentsTableProps {
  isLoading: boolean;
  experiments: Experiment[];
  deleteExperiments: (experimentIDs: number[]) => Promise<void>;
}

const ExperimentsTable = ({
  isLoading,
  experiments,
  deleteExperiments,
}: ExperimentsTableProps) => {
  const [selectedExperiments, setSelectedExperiments] = useState<Experiment[]>(
    [],
  );
  const [ownerActionButtonsDisabled, setOwnerActionButtonsDisabled] =
    useState<boolean>(false);

  const [filteredExperiments, setFilteredExperiments] =
    useState<Experiment[]>(experiments);

  useEffect(() => {
    getEmail().then((email) => {
      for (let i = 0; i < selectedExperiments.length; i++) {
        if (selectedExperiments[i].createdBy !== email) {
          setOwnerActionButtonsDisabled(true);
          return;
        }
      }
      setOwnerActionButtonsDisabled(false);
    });
  }, [selectedExperiments]);

  if (!isLoading && experiments.length === 0) {
    return (
      <div className="m-5 p-40 justify-items-center">
        <div className="text-lg font-semibold">No experiments</div>
        <div className="text-sm font-light">
          To get started create an experiment
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-row grow">
        <TableFilter
          setFilteredExperiments={setFilteredExperiments}
          experiments={experiments}
          isLoading={isLoading}
        />
        <div className="grow"></div>
        {selectedExperiments.length > 0 && (
          <OwnerControlButtons
            isDeleteLoading={isLoading}
            isDisabled={ownerActionButtonsDisabled}
            tooltip={
              !ownerActionButtonsDisabled
                ? undefined
                : "Cannot delete another's experiment"
            }
            onDelete={() => {
              const experimentIDs = selectedExperiments.map((ex) => {
                return ex.id ?? "";
              });
              deleteExperiments(experimentIDs);
              setSelectedExperiments([]);
            }}
          />
        )}
      </div>

      <Skeleton rounded={"md"} isLoaded={!isLoading}>
        <TableContainer marginTop={2} className="border-[1px] rounded-md">
          <Table variant={"simple"} size={"sm"}>
            <Thead>
              <Tr className="!bg-transparent !cursor-default">
                <Th>
                  <Checkbox
                    isIndeterminate={
                      selectedExperiments.length > 0 &&
                      selectedExperiments.length < experiments.length
                    }
                    isChecked={
                      selectedExperiments.length === experiments.length &&
                      selectedExperiments.length > 0
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        const selected: Experiment[] = [];
                        experiments.forEach((ex) => {
                          selected.push(ex);
                          setSelectedExperiments(selected);
                        });
                      } else {
                        setSelectedExperiments([]);
                      }
                    }}
                  ></Checkbox>
                </Th>
                <Th>Experiment ID</Th>
                <Th>Experiment Name</Th>
                <Th>Created By</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredExperiments.map((experiment, index) => {
                if (experiment.id) {
                  return (
                    <Tr key={index}>
                      <Td>
                        <Checkbox
                          isChecked={
                            selectedExperiments.findIndex(
                              (element: Experiment) =>
                                element.id === experiment.id,
                            ) > -1
                          }
                          onChange={(e) => {
                            const selectedExperimentsCopy =
                              structuredClone(selectedExperiments);
                            if (e.target.checked) {
                              selectedExperimentsCopy.push(experiment);
                            } else {
                              const selectedIndex =
                                selectedExperimentsCopy.findIndex(
                                  (element: Experiment) =>
                                    element.id === experiment.id,
                                );
                              if (selectedIndex > -1) {
                                selectedExperimentsCopy.splice(
                                  selectedIndex,
                                  1,
                                );
                              }
                            }
                            setSelectedExperiments(selectedExperimentsCopy);
                          }}
                        ></Checkbox>
                      </Td>
                      <Td
                        onClick={() => {
                          document.location.href = `/dashboard/experiments/${experiment.id}`;
                        }}
                      >
                        {experiment.id}
                      </Td>
                      <Td
                        onClick={() => {
                          document.location.href = `/dashboard/experiments/${experiment.id}`;
                        }}
                      >
                        {experiment.name}
                      </Td>
                      <Td
                        onClick={() => {
                          document.location.href = `/dashboard/experiments/${experiment.id}`;
                        }}
                      >
                        {experiment.createdBy}
                      </Td>
                    </Tr>
                  );
                } else {
                  return <></>;
                }
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Skeleton>
    </>
  );
};

export default ExperimentsTable;
