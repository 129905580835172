import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Dispatch, SetStateAction, useState } from "react";

import InputWithButton from "../../../components/InputWithButton/InputWithButton";
import { isValidEmail } from "../utils";
import { GetValidateUser } from "../../../api/securePlatformApi";
import { getEmail } from "../../../utils/Utils";

interface CollaboratorsProps {
  collaborators: string[];
  setCollaborators: Dispatch<SetStateAction<string[]>>;
}

const Collaborators = ({
  collaborators,
  setCollaborators,
}: CollaboratorsProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <FormControl fontFamily={"poppins"} isInvalid={!!errorMessage}>
      <FormLabel className="mt-5">Add Collaborators (optional)</FormLabel>
      <InputWithButton
        isLoading={isLoading}
        onChange={() => {
          setErrorMessage("");
        }}
        icon={<AddIcon />}
        placeholder="john.snow@company.com"
        buttonText="Add"
        onSubmit={async (newCollaborator: string, setValue) => {
          if (!isValidEmail(newCollaborator)) {
            setErrorMessage("Please enter a valid email address");
            return;
          }

          try {
            setIsLoading(true);
            await GetValidateUser(newCollaborator);
            setIsLoading(false);
          } catch (e) {
            setIsLoading(false);
            if (e instanceof Error) {
              setErrorMessage("User does not exist");

              return;
            }
          }

          if (collaborators.includes(newCollaborator)) {
            setErrorMessage("User has already been added");
            return;
          }

          if (newCollaborator === (await getEmail())) {
            setErrorMessage("You cannot add yourself");
            return;
          }

          const newCollaborators = collaborators.concat([newCollaborator]);
          setCollaborators(newCollaborators);
          setValue("");
        }}
      />
      {errorMessage === null ||
        (errorMessage === "" && (
          <FormHelperText>You can add collaborators later.</FormHelperText>
        ))}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default Collaborators;
