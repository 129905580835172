import { useEffect, useState } from "react";

import { Experiment } from "../../../types/types";
import { AddDataPoint, GetExperiment } from "../../../api/securePlatformApi";

interface useExperimentProps {
  experimentID: number | undefined;
}

const useExperiment = ({ experimentID }: useExperimentProps) => {
  const [experiment, setExperiment] = useState<Experiment>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [experimentError, setExperimentError] = useState<Error>();

  // use effect runs on load
  useEffect(() => {
    if (experimentID) {
      getExperiment(experimentID);
    }
  }, [experimentID]);

  const getExperiment = async (experimentIdToGet: number) => {
    setIsLoading(true);
    try {
      const newExperiment = await GetExperiment(experimentIdToGet);
      setExperiment(newExperiment);
    } catch (e) {
      if (e instanceof Error) {
        setExperimentError(e);
      }
    }

    setIsLoading(false);
  };

  const addDataPoint = async (
    experimentIDToAdd: number,
    variable: Record<string, string>,
  ) => {
    setIsLoading(true);
    try {
      await AddDataPoint(variable, experimentIDToAdd);
    } catch (e) {
      if (e instanceof Error) {
        // eslint-disable-next-line no-console
        console.log(e); //TODO handle this
      }
    }
    getExperiment(experimentIDToAdd);
    setIsLoading(false);
  };

  return {
    getExperiment,
    addDataPoint,
    experimentError,
    experiment,
    isLoading,
  };
};

export default useExperiment;
