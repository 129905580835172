import {
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

interface DataTableProps {
  isLoading: boolean;
  data?: Record<string, unknown>[];
}

const DataTable = ({ isLoading, data }: DataTableProps) => {
  if (!isLoading && (!!data === false || data?.length === 0)) {
    return (
      <>
        <div className="m-5 p-20 justify-items-center">
          <div className="text-lg font-semibold">No Data</div>
          <div className="text-sm font-light">
            It looks like you haven&apos;t yet begun collecting data
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Skeleton rounded={"md"} isLoaded={!isLoading} minHeight={"50px"}>
        <TableContainer marginTop={2} className="border-[1px] rounded-md">
          <Table variant={"simple"} size={"sm"}>
            <Thead>
              <Tr className="!bg-transparent !cursor-default">
                {data &&
                  data[0] &&
                  Object.keys(data[0]).map((heading, index) => (
                    <Th key={index}>{heading}</Th>
                  ))}
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.map((datapoint, index1) => (
                  <Tr key={index1}>
                    {Object.keys(data[0]).map((heading, index2) => (
                      <Td key={index2}>{datapoint[heading] + ""}</Td>
                    ))}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Skeleton>
    </>
  );
};

export default DataTable;
