import {
  fetchAuthSession,
  fetchUserAttributes,
  signOut,
} from "@aws-amplify/auth";

import { GetValidateUser } from "../api/securePlatformApi";

const getFullName = async () => {
  const response = await fetchUserAttributes();
  const firstName = response.given_name;
  const surName = response.family_name;
  return `${firstName} ${surName}`;
};

const getEmail = async () => {
  const response = await fetchUserAttributes();
  return response.email;
};

const getIdToken = async () => {
  const response = await fetchAuthSession();
  return response.tokens?.idToken;
};

const logOut = async () => {
  await signOut();
};

const isUserValid = async (username: string) => {
  try {
    await GetValidateUser(username);
  } catch {
    return false;
  }
  return true;
};

export { getFullName, getIdToken, logOut, isUserValid, getEmail };
