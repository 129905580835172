import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Paperclip } from "react-feather";

import Histogram from "./../../../images/histogram.jpeg";
import Series from "./../../../images/series.jpeg";
import Regression from "./../../../images/regression.jpeg";
import HistogramDrawer from "./HistogramDrawer";

const ManagedAnalysis = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-8">
      <Card>
        <CardBody>
          <Image src={Histogram} borderRadius="lg" />
          <Stack mt="6" spacing="3">
            <Heading size="md">Histogram</Heading>
            <Text>
              Generate a histogram analysis of your data. Once the analysis has
              been attached, you&apos;ll see the graphs appear under the
              analysis tab of your experiment.
            </Text>
          </Stack>
        </CardBody>
        <CardFooter paddingTop={0}>
          <Button
            colorScheme="red"
            rightIcon={<Paperclip size={16} />}
            onClick={onOpen}
          >
            Attach
          </Button>
        </CardFooter>
        <HistogramDrawer isOpen={isOpen} onClose={onClose} />
      </Card>
      <Card>
        <CardBody>
          <Image src={Series} borderRadius="lg" />
          <Stack mt="6" spacing="3">
            <Heading size="md">Series</Heading>
            <Text>
              Generate a series analysis of your data. Once the analysis has
              been attached, you&apos;ll see the graphs appear under the
              analysis tab of your experiment.
            </Text>
          </Stack>
        </CardBody>
        <CardFooter paddingTop={0}>
          <Button colorScheme="red" rightIcon={<Paperclip size={16} />}>
            Attach
          </Button>
        </CardFooter>
      </Card>
      <Card>
        <CardBody>
          <Image src={Regression} borderRadius="lg" />
          <Stack mt="6" spacing="3">
            <Heading size="md">Regression Model</Heading>
            <Text>
              Generate a regression analysis of your data. Once the analysis has
              been attached, you&apos;ll see the graphs appear under the
              analysis tab of your experiment.
            </Text>
          </Stack>
        </CardBody>
        <CardFooter paddingTop={0}>
          <Button colorScheme="red" rightIcon={<Paperclip size={16} />}>
            Attach
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ManagedAnalysis;
