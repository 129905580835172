/* eslint-disable no-console */
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import useExperiments from "../../experimentsPage/hooks/useExperiments";
import { Select, SelectOption } from "../../../components/Select/Select";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import { Experiment } from "../../../types/types";

interface AddVariableDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const HistogramDrawer = ({ onClose, isOpen }: AddVariableDrawerProps) => {
  const { experiments } = useExperiments();
  const [experimentOptions, setExperimentOptions] = useState<SelectOption[]>(
    [],
  );
  const [sharedVariables, setSharedVariables] = useState<string[]>([]);

  const [selectedExperiments, setSelectedExperiments] = useState<
    SelectOption[]
  >([]);

  const getExperiment = (id: number) => {
    for (let i = 0; i < experiments.length; i++) {
      console.log(id);
      console.log(experiments[i].id);
      console.log(typeof experiments[i].id);
      if (experiments[i].id === id) {
        return experiments[i];
      }
    }
    return undefined;
  };

  useEffect(() => {
    //Update shared variables

    //Resolve the selected objects
    const selectedResolvedExperiments: Experiment[] = [];
    selectedExperiments.forEach((ex) => {
      const experiment = getExperiment(ex.id as number);
      if (experiment) {
        selectedResolvedExperiments.push(experiment);
      }
      console.log("hello?");
    });

    console.log(selectedResolvedExperiments);

    // If no selected experiments - then sharedVariables is empty
    if (selectedResolvedExperiments.length === 0) {
      setSharedVariables([]);
      return;
    }

    const variableCount: Record<string, number> = {};
    selectedResolvedExperiments.forEach((experiment) => {
      const seenInThisExperiment = new Set();

      if (experiment.variables) {
        experiment.variables.forEach((variable) => {
          if (!seenInThisExperiment.has(variable.name)) {
            seenInThisExperiment.add(variable.name);
            variableCount[variable.name] =
              (variableCount[variable.name] || 0) + 1;
          }
        });
      }
    });

    // Find variables that are present in all experiments
    const newSharedVariables = Object.keys(variableCount).filter(
      (key) => variableCount[key] === selectedResolvedExperiments.length,
    );

    setSharedVariables(newSharedVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExperiments]);

  useEffect(() => {
    const newExperimentOptions: SelectOption[] = [];
    experiments.forEach((experiment) => {
      newExperimentOptions.push({
        id: experiment.id,
        value: experiment.name,
      });
    });
    setExperimentOptions(newExperimentOptions);
  }, [experiments]);

  return (
    <Drawer size={"lg"} placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader className="font-prosto" borderBottomWidth="1px">
          Attach a histogram analysis
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody className="mt-4 font-poppins">
          <FormControl>
            <FormLabel>Select an experiment</FormLabel>
            <MultiSelect
              onChange={(selected) => {
                setSelectedExperiments(selected);
              }}
              options={experimentOptions}
            />
            <FormHelperText>
              Select the experiments you wish to generate a histogram for. To
              attach this analysis to multiple experiments in one go, they must
              have at least one variable in common.
            </FormHelperText>
          </FormControl>
          {sharedVariables.length > 0 && (
            <FormControl marginTop={5}>
              <FormLabel>Select the variable to plot</FormLabel>
              <Select
                value={experimentOptions[0]}
                setValue={() => {}}
                options={experimentOptions}
              />
              <FormHelperText>
                Select the variable you wish to plot the frequency of.
              </FormHelperText>
            </FormControl>
          )}
          {sharedVariables.length > 1 && (
            <FormControl marginTop={5}>
              <FormLabel>Select the variable to group by (optional)</FormLabel>
              <Select
                value={experimentOptions[0]}
                setValue={() => {}}
                options={experimentOptions}
              />
              <FormHelperText>
                Select one or more variable to group the frequency by.
              </FormHelperText>
            </FormControl>
          )}
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            className="mr-2"
            colorScheme="red"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button colorScheme="red" variant="solid">
            Attach
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default HistogramDrawer;
