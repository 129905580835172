import { Button } from "@chakra-ui/react";
import { ArrowDownCircle } from "react-feather";

import SVG from "../../../components/shapes/hero-image.svg";
import SVGCropped from "../../../components/shapes/cropped-hero-image.svg";

const Hero = () => {
  return (
    <>
      <div className="w-[100vw] absolute right-0 h-[80vh] bg-beige -z-10"></div>
      <div className="flex flex-row h-[80vh] items-center">
        <div className="grow max-w-[90%] md:max-w-[70%] lg:max-w-[60%] align-middle">
          <h1 className="font-prosto xl:text-5xl lg:text-4xl text-3xl font-extrabold flex flex-col leading-tight">
            <span>Experimentation</span>
            <span>made simple</span>
            <span>made collaborative</span>
          </h1>
          <p className="font-poppins mt-4">
            Working with others on an experiment which evolves data entry and
            collection? Need a simple solution to do away with endless excel
            files? You’ve come to the right place; at Labvira we’ve built a
            simple and effective tool for you and your colleges to share the
            data you want to, whilst keeping it secure.
          </p>
          <Button
            marginTop={"1.5rem"}
            rightIcon={<ArrowDownCircle />}
            colorScheme="red"
            rounded={"3xl"}
            onClick={() => {
              const features = document.getElementById("#features");
              if (features) {
                features.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            SEE WHAT WE OFFER
          </Button>
        </div>
        <div className="h-[80vh] hidden md:block">
          <img
            alt=""
            className="xl:pl-24 lg:pl-12 md:pl-12 align-middle h-full hidden lg:block"
            src={SVG}
          />
        </div>
        <img
          alt=""
          className="-z-10 opacity-10 md:z-1 md:opacity-100 lg:hidden absolute top-[40%] md:top-[25%] right-0 h-[50vh]"
          src={SVGCropped}
        />
      </div>
    </>
  );
};

export default Hero;
