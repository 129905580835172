import { AddIcon, ChevronRightIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { ACCESS_DENIED } from "../../api/securePlatformApi";

import useExperiment from "./hooks/useExperiment";
import ExperimentDataOverview from "./components/ExperimentDataOverview";
import AddRecordDrawer from "./components/AddRecordDrawer";
import VariableDefinition from "./components/VariableDefinition";

const ExperimentPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pathParameters = useParams();
  const experimentID = pathParameters.experimentID;
  const {
    experimentError,
    experiment,
    isLoading,
    getExperiment,
    addDataPoint,
  } = useExperiment({
    experimentID: experimentID ? +experimentID : undefined,
  });

  const accessDenied = experimentError === ACCESS_DENIED;
  const randomError = experimentError && experimentError !== ACCESS_DENIED;

  if (accessDenied || randomError) {
    return (
      <div className="m-5 p-40 justify-items-center">
        <div className="text-lg font-semibold">
          {accessDenied ? "Access Denied" : "Something went wrong"}
        </div>
        <div className="text-sm font-light text-center">
          {accessDenied
            ? "You are not listed as a collaborator on this experiment."
            : "Please try again. If the problem persists, contact us."}
        </div>
      </div>
    );
  }

  return (
    <>
      <AddRecordDrawer
        isLoading={isLoading}
        experiment={experiment}
        addDataPoint={addDataPoint}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Breadcrumb
        paddingBottom={3}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        fontFamily={"poppins"}
        fontWeight={"300"}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/dashboard/experiments">
            Experiments
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Skeleton minWidth={"200px"} isLoaded={!isLoading}>
            <BreadcrumbLink>{experiment?.name ?? "Loading..."}</BreadcrumbLink>
          </Skeleton>
        </BreadcrumbItem>
      </Breadcrumb>

      <div className="flex">
        <Skeleton minWidth={"300px"} isLoaded={!isLoading}>
          <h1 className="font-poppins text-3xl font-semibold">
            {experiment?.name}
          </h1>
        </Skeleton>
        <div className="grow"></div>
        <Button
          marginRight={2}
          colorScheme={"red"}
          variant={"outline"}
          size={"sm"}
          fontWeight={"400"}
          rightIcon={<CopyIcon />}
          onClick={() => {
            navigator.clipboard.writeText(`exp_id_${experimentID}`);
          }}
        >
          exp_id_{experimentID}
        </Button>
        <Button
          onClick={onOpen}
          colorScheme={"red"}
          size={"sm"}
          rightIcon={<AddIcon />}
        >
          Add datapoint
        </Button>
      </div>

      <Tabs colorScheme={"red"}>
        <TabList>
          <Tab>Experiment Data</Tab>
          <Tab>Analysis</Tab>
          <Tab>Analysis Results</Tab>
          <Tab>Variables</Tab>
          <Tab>Settings</Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding={"0"} paddingTop={5}>
            <ExperimentDataOverview
              isLoading={isLoading}
              dataTableOverview={experiment?.dataTableOverview}
            />
          </TabPanel>
          <TabPanel padding={"0"} paddingTop={5}>
            Display which analyses are attached to this experiment.
          </TabPanel>
          <TabPanel padding={"0"} paddingTop={5}>
            Display the graphs / results from the attached analyses
          </TabPanel>
          <TabPanel padding={"0"} paddingTop={5} paddingBottom={10}>
            <VariableDefinition
              updateExperiments={() => {
                if (experiment?.id) {
                  getExperiment(experiment?.id);
                }
              }}
              experiment={experiment}
            />
          </TabPanel>
          <TabPanel padding={"0"} paddingTop={5}>
            Needs to: Edit collaborators, show code snippets, freeze & delete,
            publish data, publish data.
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default ExperimentPage;
