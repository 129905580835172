import { useEffect, useState } from "react";

import { Experiment, NewExperimentRequest } from "../../../types/types";
import {
  CreateExperiment,
  DeleteExperiment,
  GetUserExperiments,
} from "../../../api/securePlatformApi";

const useExperiments = () => {
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [createExperimentError, setCreateExperimentError] = useState<string>();
  const [deleteExperimentError, setDeleteExperimentError] = useState<string>();

  // use effect runs on load
  useEffect(() => {
    getExperiments();
  }, []);

  const archiveExperiment = async (experimentId: string) => {
    setIsLoading(true);
    // eslint-disable-next-line no-console
    console.log(experimentId);
    //TODO...
    // 1. call api to archive experiment
    // 2. call api to get all experiments
    // 3. set experiments to new set of experiments
    setExperiments([]);
    setIsLoading(false);
  };

  const deleteExperiments = async (experimentIds: number[]) => {
    setIsLoading(true);

    try {
      for (let i = 0; i < experimentIds.length; i++) {
        await DeleteExperiment(experimentIds[i]);
      }
    } catch (e) {
      if (e instanceof Error) {
        setDeleteExperimentError(e.message);
      } else {
        setDeleteExperimentError("Something went wrong");
      }
      setIsLoading(false);
      return;
    }

    await getExperiments();

    setIsLoading(false);
  };

  const getExperiments = async () => {
    setIsLoading(true);
    const newExperiments = await GetUserExperiments();
    setExperiments(newExperiments);
    setIsLoading(false);
  };

  const createExperiment = async (experiment: NewExperimentRequest) => {
    setIsLoading(true);
    try {
      await CreateExperiment(experiment);
    } catch (e) {
      if (e instanceof Error) {
        setCreateExperimentError(e.message);
      } else {
        setCreateExperimentError("Something went wrong");
      }
      setIsLoading(false);
      return;
    }
    await getExperiments();
    setIsLoading(false);
  };

  return {
    experiments,
    isLoading,
    archiveExperiment,
    getExperiments,
    createExperiment,
    deleteExperiments,
    createExperimentError,
    deleteExperimentError,
  };
};

export default useExperiments;
