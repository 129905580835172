import { ReactNode, useState } from "react";
import { Button, IconButton } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

import Padding from "../../padding/Padding";

import HeaderLink from "./HeaderLink";

interface HeaderLayoutProps {
  children?: ReactNode;
}

const HeaderLayout = ({ children }: HeaderLayoutProps) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] =
    useState<boolean>(false);

  const headerLinks = (isMobile: boolean): ReactNode => {
    return (
      <>
        <HeaderLink href="/contact-us" className={isMobile ? "!text-lg" : ""}>
          Contact Us
        </HeaderLink>
        <HeaderLink
          href="/developer-documentation"
          className={isMobile ? "!text-lg" : ""}
        >
          Developer Docs
        </HeaderLink>
        <Button
          as="a"
          href="/dashboard"
          marginTop={isMobile ? "0.5rem" : ""}
          size={isMobile ? "md" : "sm"}
          colorScheme="red"
          rounded={"3xl"}
        >
          CREATE EXPERIMENT
        </Button>
      </>
    );
  };

  return (
    <>
      <Padding className="bg-beige py-6 font-poppins">
        <div className="flex">
          <div className="flex-none self-center font-prosto text-3xl font-bold">
            Labvira
          </div>
          <div className="grow"></div>

          {/* Desktop header links */}
          <div
            data-testid={"desktop-header-menu"}
            className="flex-none self-center hidden md:block"
          >
            {headerLinks(false)}
          </div>

          {/* Mobile header links */}
          <div className="flex-none self-center md:hidden">
            <IconButton
              borderColor={"#00000030"}
              _hover={{
                backgroundColor: "#00000010",
                borderColor: "#00000050",
              }}
              size={"md"}
              variant={"outline"}
              aria-label="Open header menu"
              icon={<HamburgerIcon />}
              onClick={() => {
                setIsMobileMenuVisible(true);
              }}
            />
          </div>

          <div
            className={
              "z-10 bg-beige fixed w-[90vw] sm:w-[85vw] md:hidden rounded-md border-2 border-[#000000c0] px-10 sm:px-20 transition transform " +
              (isMobileMenuVisible ? "" : "translate-x-[800px]")
            }
          >
            <IconButton
              borderColor={"#00000000"}
              _hover={{
                backgroundColor: "#00000000",
                borderColor: "#00000000",
              }}
              variant={"outline"}
              aria-label="Close header menu"
              icon={<CloseIcon />}
              className={"!absolute right-1 top-1"}
              onClick={() => {
                setIsMobileMenuVisible(false);
              }}
            />
            <div className="grow flex flex-col self-center w-full gap-4 py-12">
              {headerLinks(true)}
            </div>
          </div>
        </div>

        {/* Off click background */}
        {isMobileMenuVisible && (
          <div
            className="fixed w-[100vw] h-[100vh] bg-[#00000030] top-0 left-0 z-0"
            onClick={() => {
              setIsMobileMenuVisible(false);
            }}
          />
        )}
      </Padding>
      {children}
    </>
  );
};

export default HeaderLayout;
