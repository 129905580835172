import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { SelectOption } from "../Select/Select";

type MultiSelectProps = {
  options: SelectOption[];
  onChange?: (selected: SelectOption[]) => void;
};

export const MultiSelect = ({ options, onChange }: MultiSelectProps) => {
  // State to manage selected options
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

  const toggleOption = (option: SelectOption) => {
    const updated = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updated);
    onChange?.(updated);
  };

  return (
    <Menu variant={"outline"} closeOnSelect={false}>
      <MenuButton
        variant={"outline"}
        width={"full"}
        as={Button}
        textAlign={"left"}
        rightIcon={<ChevronDownIcon />}
        fontWeight={300}
      >
        {selectedOptions.length > 0
          ? `Selected (${selectedOptions.length})`
          : "Select Experiments"}
      </MenuButton>
      <MenuList>
        <Stack spacing={1}>
          {options.map((option) => (
            <MenuItem key={option.id}>
              <Checkbox
                colorScheme="teal"
                fontFamily={"poppins"}
                isChecked={selectedOptions.includes(option)}
                onChange={() => toggleOption(option)}
              >
                {option.value}
              </Checkbox>
            </MenuItem>
          ))}
        </Stack>
      </MenuList>
    </Menu>
  );
};
