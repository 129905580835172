import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";

import { Variable } from "../../../types/types";
import useVariables from "../hooks/useVariables";

interface AddRecordDrawerProps {
  experimentID: number;
  variable: Variable;
  isOpen: boolean;
  onClose: () => void;
  updateExperiments: () => void;
}

const EditVariableDrawer = ({
  onClose,
  isOpen,
  variable,
  experimentID,
  updateExperiments,
}: AddRecordDrawerProps) => {
  const { updateVariable, error, isLoading } = useVariables();
  const [variableClone, setVariableClone] = useState<Variable>(
    structuredClone(variable),
  );

  return (
    <Drawer size={"lg"} placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader className="font-prosto" borderBottomWidth="1px">
          Edit Variable
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody className="mt-4 font-poppins">
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input disabled value={variableClone?.name}></Input>
            <FormHelperText>
              You cannot edit the variable name. If you need to - you need to
              delete the variable and re-create it. However deleting a variable
              will result in data loss.
            </FormHelperText>
          </FormControl>
          <FormControl marginTop={4}>
            <FormLabel>Type</FormLabel>
            <Input disabled value={variableClone?.type}></Input>
            <FormHelperText>
              You cannot edit the variable type. If you need to - you need to
              delete the variable and re-create it. However deleting a variable
              will result in data loss.
            </FormHelperText>
          </FormControl>
          <FormControl marginTop={4}>
            <FormLabel>Unit (Optional)</FormLabel>
            <Input
              onChange={(e) => {
                setVariableClone({
                  ...variableClone,
                  unit: e.target.value,
                });
              }}
              value={variableClone?.unit ?? ""}
            />
            <FormHelperText>
              Help other collaborators by defining the unit the data should be
              uploaded as.
            </FormHelperText>
          </FormControl>
          <FormControl marginTop={4}>
            <FormLabel>Description (Optional)</FormLabel>
            <Textarea
              onChange={(e) => {
                setVariableClone({
                  ...variableClone,
                  description: e.target.value,
                });
              }}
              value={variableClone?.description ?? ""}
            />
            <FormHelperText>
              Help other collaborators by proving a description of this
              variable.
            </FormHelperText>
          </FormControl>
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            className="mr-2"
            colorScheme="red"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            variant="solid"
            onClick={async () => {
              if (variable) {
                await updateVariable(variableClone, experimentID);
              }
              if (error) {
                return;
              }
              await updateExperiments();
              onClose();
            }}
            isLoading={isLoading}
          >
            Edit Variable
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default EditVariableDrawer;
