import { useState } from "react";

import { AddVariable, UpdateVariable } from "../../../api/securePlatformApi";
import { Variable } from "../../../types/types";

const useVariables = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const addVariable = async (variable: Variable, experimentID: number) => {
    setIsLoading(true);
    try {
      await AddVariable(
        {
          ...variable,
          unit: variable.unit === "" ? undefined : variable.unit,
          description:
            variable.description === "" ? undefined : variable.description,
        },
        experimentID,
      );
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
    }

    setIsLoading(false);
  };

  const updateVariable = async (variable: Variable, experimentID: number) => {
    setIsLoading(true);
    try {
      await UpdateVariable(
        {
          ...variable,
          unit: variable.unit === "" ? undefined : variable.unit,
          description:
            variable.description === "" ? undefined : variable.description,
        },
        experimentID,
      );
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
    }

    setIsLoading(false);
  };

  return {
    addVariable,
    updateVariable,
    error,
    isLoading,
  };
};

export default useVariables;
